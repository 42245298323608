import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic_rob from '../assets/images/rob.jpg'
import pic_thad from '../assets/images/thad.png'
import pic_yikai from '../assets/images/yikai.jpg'
import pic_matthijs from '../assets/images/matthijs.jpeg'

class Generic extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="KitchenLab &bull; People" />
        <HeaderGeneric />
        <div id="main">
          <section id="content1" className="main">
            <h2>Lab &amp; People</h2>
            <p>
              The lab was formed in 2019 to advance how we analyse and interpret
              high-throughput functional genomic (RNA, protein, and metabolite)
              data to improve medical diagnostics and therapeutics. We enjoy
              rich collaborations across Cardiology, Neurology, and Pathology at
              the Massachusetts General Hospital, Harvard Medical School, and
              elsewhere. Our research is highly applied and leans heavily on
              bioinformatics, statistics/machine-learning, and cloud computing
              to interrogate large and deeply-phenotyped genomic and clinical
              datasets.
            </p>
            <p>
              If you're interested in joining the lab feel free to get in touch
              using any medium listed at the bottom of the page.
            </p>
          </section>

          <section id="rkitchen" className="main">
            <h2>Principal Investigator</h2>
            <div className="spotlight">
              <div className="content">
                <h3>
                  <b>Robert Kitchen, PhD</b>
                </h3>
                <p>
                  Dr. Kitchen received his PhD in Physics from the University of
                  Edinburgh in 2011 and completed his post-doctoral training at
                  Yale University under the mentorship of Mark Gerstein (Systems
                  Biology) and Angus Nairn (Molecular Psychiatry). Prior to
                  joining MGH and Harvard Medical School, he spent 3 years at
                  Exosome Diagnostics where, as a Senior Informatics Scientist,
                  he made substantial contributions to several biomarker
                  development and companion-diagnostics programs prior to the
                  company’s successful acquisition by Bio-Techne in 2018. He is
                  an active member of several large and productive consortia
                  including FOUNDIN-PD, PsychENCODE, and the Extracellular RNA
                  Communication Consortium (ERCC); and he currently serves as
                  co-chair of the ERCC’s Data Analysis working group.
                </p>
                <Link
                  to="https://cvrc.massgeneral.org/faculty/robert-kitchen-phd/"
                  className="button"
                  target="_blank"
                  rel="noreferrer"
                >
                  Faculty Bio
                </Link>
              </div>
              <span className="image">
                <img src={pic_rob} alt="" />
              </span>
            </div>
          </section>

          <section id="content3" className="main">
            <h2>Current Lab Members</h2>
            <div id="tsweeney" className="spotlight">
              <div className="content">
                <h3>
                  <b>Thadryan Sweeney, MS</b>
                </h3>
                <p>
                  Thadryan joins us following completion of his masters degree
                  at Northeastern. An avid python enthusiast and a new R
                  convert, Thad is jointly appointed with the lab of Dr. Saumya
                  Das where he is working on several RNA and protein biomarker
                  discovery projects in heart failure and cardiovascular
                  disease.
                </p>
                <p>
                  <Link
                    to="https://sweeney-th.github.io/"
                    className="button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Thad's Github
                  </Link>
                </p>
              </div>
              <span className="image">
                <img src={pic_thad} alt="" style={{ width: '250px' }} />
              </span>
            </div>

            <div id="ykuo" className="spotlight">
              <div className="content">
                <h3>
                  <b>Yikai Kuo, BS</b>
                </h3>
                <p>
                  With a keen eye for design, and another Northeastern alum,
                  Yikai is jointly appointed with the lab of Dr. Steven Arnold
                  where he is working on biomarker discovery projects related to
                  neurodegeneration. Yikai's web design skills were put to
                  excellent use in developing the{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://covid.omics.kitchen"
                  >
                    interactive visualisation of COVID antibody test performance
                  </a>{' '}
                  for the{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://covidinnovation.partners.org"
                  >
                    MGB Center for COVID Innovation
                  </a>
                  .
                </p>
                <p>
                  <Link
                    to="https://yikaikuo.com/"
                    className="button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    yikaikuo.com
                  </Link>
                </p>
              </div>
              <span className="image">
                <img src={pic_yikai} alt="" style={{ width: '250px' }} />
              </span>
            </div>

            <div id="mdegeus" className="spotlight">
              <div className="content">
                <h3>
                  <b>Matthijs de Geus, BS</b>
                </h3>
                <p>
                  Thanks to COVID-19, Matthijs' Masters project visit from the
                  University of Amsterdam is purely virtual. Despite this
                  unexpected logistical challenge, Matt is enthusiastically
                  working to analyse a novel protein atlas of the Rhesus Macaque
                  brain and understand how this relates to human brain
                  development and evolution.
                </p>
              </div>
              <span className="image">
                <img src={pic_matthijs} alt="" style={{ width: '250px' }} />
              </span>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
